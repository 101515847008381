.streamLobbyItemProSlotWrapper,
.streamLobbyItemBuyerSlotWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #333333;
    padding: 8px;
    border-radius: 12px;
    max-width: 260px;
    min-height: 165px;
    height: auto !important;
}

.streamLobbyItemEmptySlotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 12px;
    max-width: 260px;
    min-height: 165px;
    font-family: 'ABC Social', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 110%;
    color: #7A7A7A;
    border: 1px solid #4D4D4D;
    height: auto !important;
}

.streamLobbySlotFirstRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
}

.streamLobbyItemPlayerAvatar {
    width: 44px;
    height: 44px;
    border-radius: 8px;
    display: block;
    margin-right: 8px;
}

.streamLobbySlotMainInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.streamLobbyItemProNickname {
    font-family: 'ABC Social', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 110%;
    text-align: left;
    color: #FFFFFF;
    width: 100%;
    max-width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
    white-space: nowrap;
}

.streamLobbyItemBuyer {
    font-family: 'ABC Social', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    color: #F2F2F2;
}

.streamLobbyProInfo,
.streamLobbyProRatingBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.streamLobbyProRating {
    font-family: 'ABC Social', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #F2F2F2;
    margin: 0 8px 0 4px;
}

.streamLobbyProRank {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 11.05px;
    line-height: 100%;
    color: #F7F7F7;
    margin: 0 3px;
}

.streamLobbySlotDescription {
    font-family: 'ABC Social', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 110%;
    color: #DFDFDF;
    margin-bottom: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.streamLobbySlotTagsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    flex-wrap: wrap;
}

.streamLobbySlotTag {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    background: #262626;
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 130%;
    text-align: center;
    color: #F2F2F2;
    min-height: 25px;
}

@media (max-width: 850px) {
    .streamLobbyItemProSlotWrapper {
        flex-direction: column;
        margin-right: 12px;
        align-items: flex-start;
    }

    .streamLobbyItemPlayerAvatar {
        margin: 0 8px 4px 0;
    }

    .streamLobbySlotDescription {
        font-family: 'ABC Social', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 130%;
        color: #DFDFDF;
        height: 35px !important;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 12px;
    }

    .streamLobbyItemProNickname{
        text-align: left;
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 13px;
        line-height: 130%;
        color: #FFFFFF;
        opacity: 0.6;
        white-space: initial;
        word-break: break-all;
    }
    .streamLobbyItemProSlotWrapper,
    .streamLobbyItemBuyerSlotWrapper,
    .streamLobbyItemEmptySlotWrapper {
        max-width: 238px;
        min-height: 185px;
    }
}
