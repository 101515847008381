.streamLobbyItemProSlotWrapper, .streamLobbyItemBuyerSlotWrapper {
  max-width: 260px;
  min-height: 165px;
  background: #333;
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  display: flex;
  height: auto !important;
}

.streamLobbyItemEmptySlotWrapper {
  max-width: 260px;
  min-height: 165px;
  color: #7a7a7a;
  border: 1px solid #4d4d4d;
  border-radius: 12px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  font-family: ABC Social, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  display: flex;
  height: auto !important;
}

.streamLobbySlotFirstRow {
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  display: flex;
}

.streamLobbyItemPlayerAvatar {
  width: 44px;
  height: 44px;
  border-radius: 8px;
  margin-right: 8px;
  display: block;
}

.streamLobbySlotMainInfo {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.streamLobbyItemProNickname {
  text-align: left;
  color: #fff;
  width: 100%;
  max-width: 190px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 5px;
  font-family: ABC Social, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  overflow: hidden;
}

.streamLobbyItemBuyer {
  color: #f2f2f2;
  font-family: ABC Social, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}

.streamLobbyProInfo, .streamLobbyProRatingBlock {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.streamLobbyProRating {
  color: #f2f2f2;
  margin: 0 8px 0 4px;
  font-family: ABC Social, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.streamLobbyProRank {
  color: #f7f7f7;
  margin: 0 3px;
  font-family: Inter, sans-serif;
  font-size: 11.05px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}

.streamLobbySlotDescription {
  color: #dfdfdf;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 12px;
  font-family: ABC Social, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  display: -webkit-box;
  overflow: hidden;
}

.streamLobbySlotTagsWrapper {
  flex-flow: wrap;
  align-items: center;
  gap: 4px;
  display: flex;
}

.streamLobbySlotTag {
  text-align: center;
  color: #f2f2f2;
  min-height: 25px;
  background: #262626;
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  display: flex;
}

@media (max-width: 850px) {
  .streamLobbyItemProSlotWrapper {
    flex-direction: column;
    align-items: flex-start;
    margin-right: 12px;
  }

  .streamLobbyItemPlayerAvatar {
    margin: 0 8px 4px 0;
  }

  .streamLobbySlotDescription {
    color: #dfdfdf;
    text-overflow: ellipsis;
    margin-bottom: 12px;
    font-family: ABC Social, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    overflow: hidden;
    height: 35px !important;
  }

  .streamLobbyItemProNickname {
    text-align: left;
    color: #fff;
    opacity: .6;
    white-space: initial;
    word-break: break-all;
    font-family: Inter, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 130%;
  }

  .streamLobbyItemProSlotWrapper, .streamLobbyItemBuyerSlotWrapper, .streamLobbyItemEmptySlotWrapper {
    max-width: 238px;
    min-height: 185px;
  }
}

/*# sourceMappingURL=index.a10e8f68.css.map */
